import React from "react";
import Rollbar from '../utils/RollbarTracking'
import axios from 'axios'
import { Link } from 'react-router-dom';

export class OrderDetails extends React.Component {

  state = {
    mySupplierData: {},
    myOrderData: [],
    orderLineItems: [],
    loading: false,
    hiddenPrices: false,
    supplierSettings: {integration:{}}
  }

  componentWillMount() {
    this.props.match.params.id ? this.fetchOrderData() : this.props.history.push("/previousorders");
  }

  fetchSupplierData = async () => {
    axios.get('/suppliers/' + this.state.myOrderData.supplierID)
      .then(data => {
        console.log(data);
        this.setState({
          mySupplierData: data[0],
          loading: true
        });
        try {
          this.setState({
            supplierSettings: JSON.parse(data[0].settings)
          });
        } catch (error) {
          console.log(error)
        }
      })
      .catch(err => {
        console.log(err)
        Rollbar.error(err)
      })
  }

  fetchOrderData = async () => {
    axios.get('/orders/' + this.props.match.params.id)
      .then(data => {
        console.log(data);
        this.setState({
          myOrderData: data[0]
        });
        this.fetchOrderLineItems();
      })
      .catch(err => {
        console.log(err)
        Rollbar.error(err)
      })
  }

  fetchOrderLineItems = async () => {
    axios.get('/orders/' + this.props.match.params.id + '/lineitems')
      .then(data => {
        console.log(data);
        var hiddenPrices = data.results.some(e => e.showPrice === 0)
        this.setState({
          orderLineItems: data.results,
          hiddenPrices: hiddenPrices
        });
        this.fetchSupplierData();
      })
      .catch(err => {
        console.log(err)
        Rollbar.error(err)
      })
  }

  downloadInvoice = async () => {
    axios.get('/orders/' + this.props.match.params.id + '/invoiceurl')
      .then(data => {
        console.log(data);
        window.open(data.url)
      })
      .catch(err => {
        console.log(err)
        Rollbar.error(err)
      })
  }

  render() {
    return (
      <section className="space--xxs">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3>Order Details</h3>
            </div>
            <div className="col-md-6 text-right btn-xs-left">
              {(this.state.myOrderData.status === "Invoiced" || this.state.myOrderData.status === "Paid") && !this.state.supplierSettings.integration.type ? <a className="btn btn--primary-1 mb-0 downloadBtn" onClick={() => this.downloadInvoice()}> <span className="btn__text">Download Invoice</span> </a> : null}
              {this.state.mySupplierData.supplierID ? <a className="btn btn--primary-1 reorderBtn" onClick={() => this.props.history.push("/neworder/products/" + this.state.mySupplierData.supplierID + "/reorder/" + this.state.myOrderData.orderID)}> <span className="btn__text">Reorder</span> </a> : null}
            </div>
          </div>
          <div className="row">
            {this.state.loading ? (
              <div className="col-md-7">
                {this.state.orderLineItems.map((data, i) =>
                  <div key={i} className="boxed box-shadow product-card-img">
                    <div className="col-xs-2 col-sm-2 prod-img">
                      {data.photo ? <img src={data.photo.replace('product_', 'resized-product_')}></img> : <img src="https://orderfresh-image-bucket-prod.s3-ap-southeast-2.amazonaws.com/productimgplaceholder_small.png" alt="box" className="media-object" />}
                    </div>
                    <div className="col-xs-7 col-sm-8 product-card-body">
                      <h5>{data.name}</h5>
                      <p>
                        {data.description}
                      </p>
                    </div>
                    <div className="col-xs-3 col-sm-2 product-card-body">
                      <h5>{data.quantity} x</h5>
                      <h5>{data.showPrice || this.state.myOrderData.status === 'Invoiced' || this.state.myOrderData.status === 'Paid' ? '$' + data.unitPrice + '/' : null}{data.unit ? data.unit : 'unit'}</h5>
                    </div>
                  </div>)}
              </div>) : null}
            {this.state.loading ? (
              <div className="col-md-5">
                <div className="boxed boxed--lg boxed--border bg--secondary box-shadow">
                  <div className="text-block prev-orders-card">
                    <h4>Order # {this.state.myOrderData.orderID}</h4>
                    <h5>Status: {this.state.myOrderData.status}</h5>
                    <h5>Supplier: {this.state.mySupplierData.name}</h5>
                    <p>Phone: {this.state.mySupplierData.phone}</p>
                    <p>Address: {this.state.mySupplierData.address}</p>
                    <h5>Date Placed: {this.state.myOrderData.date}</h5>
                    <h5>Delivery Day: {this.state.myOrderData.deliveryDay}</h5>
                    <h5>Payment Due by: {this.state.myOrderData.paymentDue}</h5>
                    {this.state.myOrderData.adjustment ? <h4 className="h-nopad">Adjustment: ${this.state.myOrderData.adjustment}</h4> : null}
                    <h4 className="h-nopad">Total: ${this.state.hiddenPrices && this.state.myOrderData.status != 'Invoiced' && this.state.myOrderData.status != 'Paid' ? '0.00*' : this.state.myOrderData.total}</h4>
                    {this.state.hiddenPrices && this.state.myOrderData.status != 'Invoiced' && this.state.myOrderData.status != 'Paid' ? <p className="small lh-1">* Order total will be shown once the order has been invoiced</p> : null}
                    <h5>Comment: {this.state.myOrderData.comment}</h5>
                  </div>
                </div>
                <Link className="btn btn--primary btn-full" to={'/previousorders'}><span className="btn__text">Return to Previous Orders</span></Link>
              </div>) : null}
          </div>
        </div>
      </section>
    );
  }
}
