import React from 'react';
import axios from 'axios'
import Cache from '@aws-amplify/cache';

import Register from './register'
import RequestReset from './requestReset'
import ResetPassword from './resetPassword'

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    pageView: 'login',
    error: false
  }

  componentDidMount() {
    if (window.location.pathname.split('/')[2] === 'reset') {
      this.setState({ pageView: 'resetPassword' })
    }
    if (window.location.pathname.split('/')[1] === 'settings') {
      this.setState({ pageView: 'register' })
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value, error: false })
  }

  showRegisterPage() {
    this.setState({ pageView: 'register' })
  }

  showLogin() {
    this.setState({ pageView: 'login' })
  }

  showResetPassword() {
    this.setState({ pageView: 'requestReset' })
  }

  login() {
    axios.post('/auth/login', {
      email: this.state.email,
      password: this.state.password
    })
      .then(data => {
        console.log(data)
        if (data.token) {
          Cache.setItem('auth_token', data.token)
          window.location.replace(window.location.pathname.split('/')[1] === 'settings' ? window.location.pathname : '/')
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({ error: true })
      })
  }

  render() {
    return (
      <div>
        {this.state.pageView === 'register' ? <Register showLogin={this.showLogin.bind(this)} /> : null}
        {this.state.pageView === 'requestReset' ? <RequestReset showLogin={this.showLogin.bind(this)} /> : null}
        {this.state.pageView === 'resetPassword' ? <ResetPassword showLogin={this.showLogin.bind(this)} token={window.location.pathname.split('/')[3]} /> : null}
        {this.state.pageView === 'login' ?
          <div className="main-container">
            <section className="imageblock switchable feature-large height-100">
              <div className="imageblock__content col-md-6 col-sm-4 pos-right">
                <div className="background-image-holder">
                  <img alt="image" src="/login-bg.jpg" />
                </div>
              </div>
              <div className="container pos-vertical-center">
                <div className="row">
                  <div className="col-md-5 col-sm-7">
                    <img className="logo login-logo" alt="logo" src="/logo.png" />
                    <h2>Login</h2>
                    <form onSubmit={e => e.preventDefault()}>
                      <div className="row">
                        <div className="col-xs-12">
                          <input type="email" placeholder="Email Address" id="inputEmail" name="email" value={this.state.email} onChange={this.handleChange.bind(this)} />
                        </div>
                        <div className="col-xs-12">
                          <input type="password" placeholder="Password"  id="inputPassword" name="password" value={this.state.password} onChange={this.handleChange.bind(this)} onKeyPress={(e) => {if(e.key === 'Enter'){this.login()}}}/>
                        </div>
                        <div className="col-xs-12">
                          <button className="btn btn--primary type--uppercase login-btn" onClick={this.login.bind(this)}>Sign in</button>
                        </div>
                        <div className="col-xs-12">
                          {this.state.error ? 
                          <div class="alert bg--error">
                            <div class="alert__body">
                              <span>Email or password is wrong</span>
                            </div>
                          </div> : null }
                        </div>
                        <div className="col-xs-12">
                          <span className="type--fine-print block">Dont have an account yet?
                            <a href="#fix" onClick={this.showRegisterPage.bind(this)}> Create account</a>
                          </span>
                          <span className="type--fine-print block">Forgot your password?
                            <a href="#fix" onClick={this.showResetPassword.bind(this)}> Reset password</a>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
          : null}
      </div>
    )
  }
}

export default Login;
