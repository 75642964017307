import React from 'react';
import Rollbar from '../../utils/RollbarTracking'
import axios from 'axios'
import { toast } from 'react-toastify';

class Register extends React.Component {
  state = {
    email: '',
    password: '',
    phone: '',
    firstname: '',
    lastname: '',
    company: '',
    address: ''
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value})
  }

  formatMobile(value) {
    // Reformat phone number to correct format
    value = value.replace(/\D/g, ''); // Remove non-numeric characters

    // Handle different input formats
    if (value.startsWith('61')) {
      value = value.slice(2); // Remove leading '61'
    } 
    if (value.startsWith('0')) {
      value = value.slice(1); // Remove leading '0'
    }

    if (value.length > 9) {
      value = value.slice(0, 9); // Ensure max length of 9 digits
    }

    return value;
  }

  signUp() {
    let phone = this.state.phone;
    phone = this.formatMobile(phone);
    axios.post('/auth/register', {
        email: this.state.email,
        password: this.state.password,
        phone: phone,
        firstName: this.state.firstname,
        lastName: this.state.lastname,
        company: this.state.company,
        address: this.state.address
      })
      .then(data => {
        console.log(data)
        if (data.error === 'account_exists') {
          toast.warning('Account already exists with the same email')
        } else {
          this.props.showLogin()
        }
      })
      .catch(err => {
        console.log(err)
        Rollbar.error(err)
        toast.error('Unable to register new account')
      })
  }

  render() {
    return (
      <div className="main-container">
        <section className="imageblock switchable feature-large height-100">
          <div className="imageblock__content col-md-6 col-sm-4 pos-right">
            <div className="background-image-holder">
              <img alt="image" src="/login-bg.jpg" />
            </div>
          </div>
          <div className="container pos-vertical-center pb-1">
            <div className="row">
              <div className="col-md-5 col-sm-7">
                <img className="logo login-logo" alt="logo" src="/logo.png" />
                <h2>Register</h2>
                <form onSubmit={e => e.preventDefault()}>
                  <div className="row">
                    <div className="col-xs-12">
                      <input type="email" placeholder="Email Address" id="inputEmail" name="email" value={this.state.email} onChange={this.handleChange.bind(this)} />
                    </div>
                    <div className="col-xs-12">
                      <input type="password" placeholder="Password"  id="inputPassword" name="password" value={this.state.password} onChange={this.handleChange.bind(this)} onKeyPress={(e) => {if(e.key === 'Enter'){this.login()}}}/>
                    </div>
                    <div className="col-xs-12 mb-0">
                      <input type="text" placeholder="Mobile"  id="inputPhone" name="phone" value={this.state.phone} onChange={this.handleChange.bind(this)} />
                      <small className="input-help text-muted">9 digit Mobile number e.g. 412992853</small>
                    </div>
                    <div className="col-xs-12">
                      <input type="text" placeholder="First Name"  id="inputFirstName" name="firstname" value={this.state.firstname} onChange={this.handleChange.bind(this)} />
                    </div>
                    <div className="col-xs-12">
                      <input type="text" placeholder="Last Name"  id="inputLastName" name="lastname" value={this.state.lastname} onChange={this.handleChange.bind(this)} />
                    </div>
                    <div className="col-xs-12">
                      <input type="text" placeholder="Store Name"  id="inputCompany" name="company" value={this.state.company} onChange={this.handleChange.bind(this)} />
                    </div>
                    <div className="col-xs-12">
                      <input type="text" placeholder="Store Address"  id="inputAddress" name="address" value={this.state.address} onChange={this.handleChange.bind(this)} />
                    </div>
                    <div className="col-xs-12">
                      <button className={this.state.email && this.state.password && this.state.phone && this.state.firstname && this.state.lastname && this.state.company && this.state.address? "btn btn--primary type--uppercase login-btn" : "btn btn--primary type--uppercase login-btn disabled"} onClick={this.signUp.bind(this)} >Sign up</button>
                    </div>
                    <div className="col-xs-12">
                      <span className="type--fine-print block">Already have an Account?
                        <a href="#fix" onClick={this.props.showLogin}> Sign in</a>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Register;
