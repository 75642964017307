import React from "react";
import { Link } from 'react-router-dom';

import {ReactComponent as ErrorVector} from './error.svg';
import './error.css'

export class APIError extends React.Component {
    render() {
        return (
            <section className="space--xxs">
                <div className="container text-center">
                    <ErrorVector />
                    <h1>Oops! Something broke.</h1>
                    <Link className="btn btn--primary" to={'/home'}><span className="btn__text">Return to home</span></Link>
                </div>
            </section>
        );
    }
}
