import Rollbar from 'rollbar';

const config = require('../config.json')

var RollbarObj = new Rollbar({
    accessToken: config.rollbar1,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: "production"
    }
});

const error = (err) => {
    if (config.rollbar1) {
        RollbarObj.error(err);
    }
}

export default {
    error: error
};