import React from "react";
import Rollbar from '../utils/RollbarTracking'
import axios from 'axios'
import { Link } from 'react-router-dom';

export class Suppliers extends React.Component {

    componentWillMount() {
      this.fetchSupplierList();
    }

    state = {
        suppliers: []
    }

    fetchSupplierList = async () => {
      axios.get('/suppliers')
      .then(resp => {
        console.log(resp)
        this.setState({
          suppliers: resp
        });
      })
      .catch (err => {
        console.log(err)
        Rollbar.error(err)
      })
    }

    render() {
        return (
            <section className="space--xxs">
              <div className="container">
                <h3>Choose a Supplier</h3>
                <div className="row">
                  <div className="col-md-12">
                    <table className="border--round table--alternate-row box-shadow">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Address</th>
                          <th className="hidden-xs">Phone</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.suppliers.map((data, i) =>
                        <tr key={i}>
                          <td>{data.name}</td>
                          <td>{data.address}</td>
                          <td className="hidden-xs">{data.phone}</td>
                          <td><Link className="btn btn--sm btn--primary" to={'/neworder/products/' + data.supplierID}><span className="btn__text">View</span></Link></td>
                        </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
        );
    }
}
