import React from "react";

export class Billing extends React.Component {

  render() {
    return (
      <div id="account-billing" className="account-tab">
        <h4>Billing Details</h4>
        <div className="boxed boxed--border bg--secondary">
          <h5>Payment Methods</h5>
          <hr />
          <form>
            <div className="row">
              <ul>
                <li>
                  <div className="col-sm-6">
                    <p>
                      <i className="material-icons">credit_card</i>
                      <span> 
                        Mastercard ending in<strong>4722</strong>
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-3 text-right text-left-xs">
                    <button type="submit" className="btn bg--error">Remove</button>
                  </div>
                  <div className="col-sm-3 text-right text-left-xs">
                    <button type="submit" className="btn">Edit</button>
                  </div>
                </li>
              </ul>
            </div>
            <hr />
            <button type="submit" className="btn">Add New Method</button>
          </form>
        </div>
      </div>
    );
  }
}