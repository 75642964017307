import React from "react";

export class Footer extends React.Component {

    render() {
        return (
          <footer className="footer-7 text-center-xs bg--secondary">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12 text-center">
                          <span className="type--fine-print">&copy;
                          <span className="update-year">{new Date().getFullYear()}</span> OrderFresh &mdash; All Rights Reserved</span>
                      </div>
                  </div>
              </div>
          </footer>
        );
    }
}
