import React from "react";
import Rollbar from '../../utils/RollbarTracking'
import axios from 'axios'

import { toast } from 'react-toastify';

export class Profile extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    name: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    email2: '',
    errors: {}
  }

  componentWillMount() {
    this.fetchCustomerData();
  }

  handleChange(evt) {
    var errors = this.state.errors
    if (evt.target.name === 'name') {
      errors[evt.target.name] = !/^[A-Za-z\s]+$/.test(evt.target.value)
    } else if (evt.target.name === 'company') {
      errors[evt.target.name] = !evt.target.value
    } else if (evt.target.name === 'email') {
      errors[evt.target.name] = !/[^@]+@[^\.]+\..+/.test(evt.target.value)
    } else if (evt.target.name === 'phone') {
      errors[evt.target.name] = !/^[0-9\s+()]+$/.test(evt.target.value)
    } else if (evt.target.name === 'address') {
      errors[evt.target.name] = !/^[A-Za-z0-9\s,]+$/.test(evt.target.value)
    } else if (evt.target.name === 'email2') {
      errors[evt.target.name] = !/[^@]+@[^\.]+\..+/.test(evt.target.value)
    }
    this.setState({ [evt.target.name]: evt.target.value, errors: errors });
  }

  fetchCustomerData = async () => {
    axios.get('/user')
      .then(data => {
        console.log(data);
        this.setState({
          name: data[0].name,
          email: data[0].email,
          phone: data[0].phone,
          company: data[0].company,
          address: data[0].address,
          email2: data[0].email2
        });
      })
      .catch(err => {
        console.log(err)
        Rollbar.error(err)
      })
  }

  saveProfile = async () => {
    let requestParams = {
        'name': this.state.name,
        'email': this.state.email,
        'phone': this.state.phone,
        'company': this.state.company,
        'address': this.state.address,
        'email2': this.state.email2
    }
    axios.post('/user/update', requestParams)
      .then(data => {
        console.log(data);
        this.fetchCustomerData();
        this.props.updateSidebar()
        toast.success("Profile details saved");
      })
      .catch(err => {
        console.log(err)
        Rollbar.error(err)
      })
  }

  render() {
    return (
        <div id="account-profile" className="account-tab">
            <h4>Profile</h4>
            <form>
                <div className="row">
                    <div className="col-sm-12">
                        <label>Full Name:</label>
                        <input type="text" value={this.state.name} onChange={this.handleChange} name="name" className={this.state.errors.name ? "invalid-input" : null} />
                    </div>
                    <div className="col-sm-12">
                        <label>Email Address (Primary):</label>
                        <input type="email" value={this.state.email} onChange={this.handleChange} name="email" className={this.state.errors.email ? "invalid-input" : null} />
                    </div>
                    <div className="col-sm-12">
                        <label>Email Address (Secondary):</label>
                        <input type="email" value={this.state.email2} onChange={this.handleChange} name="email2" className={this.state.errors.email2 ? "invalid-input" : null} />
                    </div>
                    <div className="col-sm-12">
                        <label>Store Name:</label>
                        <input type="text" value={this.state.company} onChange={this.handleChange} name="company" className={this.state.errors.company ? "invalid-input" : null} />
                    </div>
                    <div className="col-sm-12">
                        <label>Store Address:</label>
                        <input type="text" value={this.state.address} onChange={this.handleChange} name="address" className={this.state.errors.address ? "invalid-input" : null} />
                    </div>
                    <div className="col-sm-12">
                        <label>Phone Number:</label>
                        <input type="text" value={this.state.phone} onChange={this.handleChange} name="phone" className={this.state.errors.phone ? "invalid-input" : null} />
                    </div>
                    <div className="col-md-3 col-sm-4 text-right">
                        <button 
                          type="button" 
                          className="btn btn--primary type--uppercase btn-full" 
                          onClick={this.saveProfile.bind(this)} 
                          disabled={this.state.errors.name || this.state.errors.email || this.state.errors.company || this.state.errors.phone || this.state.errors.address}
                        >Save Profile</button>
                    </div>
                </div>
            </form>
        </div>
    );
  }
}