import React from 'react';
import Rollbar from '../../utils/RollbarTracking'
import axios from 'axios'
import { toast } from 'react-toastify';

class RequestReset extends React.Component {
   state = {
      email: '',
      success: false
   }

   handleChange(event) {
      this.setState({ [event.target.name]: event.target.value })
   }

   requestReset() {
      axios.post('/auth/reset/request', {
         email: this.state.email,
      })
         .then(data => {
            console.log(data)
            this.setState({ success: true })
         })
         .catch(err => {
            console.log(err)
            Rollbar.error(err)
            toast.error('Failed to request password reset email')
         })
   }

   render() {
      return (
         <div className="main-container">
            <section className="imageblock switchable feature-large height-100">
               <div className="imageblock__content col-md-6 col-sm-4 pos-right">
                  <div className="background-image-holder">
                     <img alt="image" src="/login-bg.jpg" />
                  </div>
               </div>
               <div className="container pos-vertical-center">
                  <div className="row">
                     <div className="col-md-5 col-sm-7">
                        <img className="logo login-logo" alt="logo" src="/logo.png" />
                        <h2>Reset Password</h2>
                        {this.state.success ?
                        <div className="text-center pt-3">
                           You will shortly recieve an email containing a link to reset your password. Please note this email will expire in 24 hours.
                        </div> :
                        <form onSubmit={e => e.preventDefault()}>
                           <div className="row">
                              <div className="col-xs-12">
                                 <input type="email" placeholder="Email Address" id="inputEmail" name="email" value={this.state.email} onChange={this.handleChange.bind(this)} />
                              </div>
                              <div className="col-xs-12">
                                 <button className="btn btn--primary type--uppercase login-btn" onClick={this.requestReset.bind(this)}>Submit</button>
                              </div>
                              <div className="col-xs-12">
                                 <span className="type--fine-print block">Remember your login details?
                                    <a href="#fix" onClick={this.props.showLogin}> Sign in</a>
                                 </span>
                              </div>
                           </div>
                        </form>}
                     </div>
                  </div>
               </div>
            </section>
         </div>
    )
   }
}

export default RequestReset;
