import React from "react";
import Rollbar from '../utils/RollbarTracking'
import axios from 'axios'
import { Link } from 'react-router-dom';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

export class PreviousOrders extends React.Component {

    componentWillMount() {
      this.fetchOrderList();
    }

    state = {
        orders: [],
        statusFilter: 'all'
    }

    fetchOrderList = async () => {
      axios.get('/orders')
      .then(resp => {
        this.setState({
          orders: resp
        });
      })
      .catch (err => {
        console.log(err)
        Rollbar.error(err)
      })
    }

    cellButton(cell, row, enumObject, rowIndex) {
      return (
        <Link className="btn btn--sm btn--primary" to={'/previousorders/orderdetails/' + row.orderID}><span className="btn__text">View</span></Link>
      )
    }

    handleFilter1 = () => {
      this.refs.statusCol.applyFilter('');
      this.setState({
          statusFilter: "all"
      });
    }

    handleFilter2 = () => {
      this.refs.statusCol.applyFilter('Pending');
      this.setState({
          statusFilter: "pending"
      });
    }

    handleFilter3 = () => {
      this.refs.statusCol.applyFilter('Accepted');
      this.setState({
          statusFilter: "accepted"
      });
    }

    handleFilter4 = () => {
      this.refs.statusCol.applyFilter('Invoiced');
      this.setState({
          statusFilter: "invoiced"
      });
    }

    handleFilter5 = () => {
      this.refs.statusCol.applyFilter('Paid');
      this.setState({
          statusFilter: "paid"
      });
    }

    statusFormatter(cell, row) {
      if (cell === 'Pending') {
        return '<span class="text-warning">Pending</span>'
      }
      else if (cell === 'Accepted') {
        return '<span class="text-info">Accepted</span>'
      }
      else if (cell === 'Invoiced') {
        return '<span class="text-success">Invoiced</span>'
      }
      else if (cell === 'Paid') {
        return '<span class="text-secondary">Paid</span>'
      }
    }

    render() {
        return (
            <section className="space--xxs">
              <div className="container">
                <h3>Previous Orders</h3>
                <div className="row">
                  <div className="col-md-12">
                    <div className="orderFilter">
                      <a onClick={this.handleFilter1} className={this.state.statusFilter === "all" ? "active" : null}>All</a>
                      <a onClick={this.handleFilter2} className={this.state.statusFilter === "pending" ? "active" : null}>Pending</a>
                      <a onClick={this.handleFilter3} className={this.state.statusFilter === "accepted" ? "active" : null}>Accepted</a>
                      <a onClick={this.handleFilter4} className={this.state.statusFilter === "invoiced" ? "active" : null}>Invoiced</a>
                      <a onClick={this.handleFilter5} className={this.state.statusFilter === "paid" ? "active" : null}>Paid</a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <BootstrapTable data={this.state.orders} options={{defaultSortName: 'orderID', defaultSortOrder: 'desc', hideSizePerPage: true}} pagination bordered={ false } hover={ false } tableHeaderClass='border--round table--alternate-row box-shadow' tableBodyClass='border--round table--alternate-row box-shadow'>
                      <TableHeaderColumn dataField="orderID" isKey={true} className="hidden-xs" columnClassName="hidden-xs">Order No</TableHeaderColumn>
                      <TableHeaderColumn dataField="name" dataSort={true}>Supplier</TableHeaderColumn>
                      <TableHeaderColumn dataField="date" dataSort={true}>Date</TableHeaderColumn>
                      <TableHeaderColumn dataField="total" dataSort={true} dataFormat={(cell, row) => row.hideTotal ? '$0.00*' : '$' + cell}>Total</TableHeaderColumn>
                      <TableHeaderColumn ref='statusCol' dataField="status" filter={ { type: 'TextFilter' } } className="hidden-xs" columnClassName="hidden-xs" dataFormat={this.statusFormatter}>Status</TableHeaderColumn>
                      <TableHeaderColumn dataFormat={this.cellButton}>Action</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
            </section>
        );
    }
}
