import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import axios from 'axios'
import axiosRetry from 'axios-retry';
import Cache from '@aws-amplify/cache';
import { toast } from 'react-toastify';

import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

import Login from './views/auth/login'

import { Home } from "./views/Home";
import { NewOrder } from "./views/NewOrder";
import { PreviousOrders } from "./views/PreviousOrders";
import { Suppliers } from "./views/Suppliers";
import { OrderDetails } from "./views/OrderDetails";
import { Settings } from "./views/Settings";
import { APIError } from "./views/error";

import GA from './utils/GoogleAnalytics'
// import FreshChat from './utils/Freshchat'
import Rollbar from './utils/RollbarTracking'

import 'react-toastify/dist/ReactToastify.css';

const config = require('./config.json')

axios.defaults.headers.common = {'Authorization': 'Bearer ' + Cache.getItem('auth_token')}
axios.defaults.baseURL = config.api

// Set response to data
axios.interceptors.response.use(response => {
  return response.data;
})

axiosRetry(axios, { retries: 3 });

toast.configure()

Cache.configure({ defaultTTL: 1209600000 });

class App extends Component {
  state = {
    loggedIn: Cache.getItem('auth_token') ? true : false,
    user: {},
    myCustomerData: []
  }

  componentDidMount() {
    this.fetchCustomerData();
  }

  fetchCustomerData = async () => {
      axios.get('/user')
      .then(data => {
        console.log(data[0]);
        this.setState({
          myCustomerData: data[0]
        });
      })
      .catch (err => {
        console.log(err);
        // Rollbar.error(err)
      })
  }

  render() {
    return (
      <div>
        <BrowserRouter>
          {this.state.loggedIn ? 
          <div className="body-margin">
            <div className="nav-container">
              <Header userName={this.state.myCustomerData.name}/>
            </div>
            <div className="main-container">
              { GA.init() && <GA.RouteTracker /> }
              <Switch>
                <Route exact path='/home' render={(props) => (<Home {...props} />)} />
                <Route exact path='/neworder' render={(props) => (<Suppliers {...props} />)} />
                <Route exact path='/neworder/products' render={(props) => (<NewOrder {...props} />)} />
                <Route exact path='/neworder/products/:id' render={(props) => (<NewOrder {...props} />)} />
                <Route exact path='/neworder/products/:id/reorder/:prevOrderId' render={(props) => (<NewOrder {...props} />)} />
                <Route exact path='/previousorders' render={(props) => (<PreviousOrders {...props} />)} />
                <Route exact path='/previousorders/orderdetails' render={(props) => (<OrderDetails {...props} />)} />
                <Route exact path='/previousorders/orderdetails/:id' render={(props) => (<OrderDetails {...props} />)} />
                <Route exact path='/settings' render={(props) => (<Settings {...props} />)} />
                <Route exact path='/settings/:id' render={(props) => (<Settings {...props} />)} />
                <Route exact path='/settings/:id/:extID' render={(props) => (<Settings {...props} />)} />
                <Route exact path='/error' render={(props) => (<APIError {...props} />)} />
                <Redirect exact from="/" to="/home" />
              </Switch>
            </div>
            <div className="footer">
              <Footer />
            </div>
            {/* <FreshChat
              token="d39f6dec-d41b-4e88-b1b4-13fffecd70ab"
              onInit={widget => {
                widget.user.setFirstName(this.state.myCustomerData.name);
                widget.user.setEmail(this.state.myCustomerData.email);
              }}
            /> */}
          </div> : <Login />}
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
