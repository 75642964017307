import React from 'react';
import Rollbar from '../../utils/RollbarTracking'
import axios from 'axios'
import { toast } from 'react-toastify';

class ResetPassword extends React.Component {
   state = {
      token: this.props.token,
      password: ''
   }

   handleChange(event) {
      this.setState({ [event.target.name]: event.target.value })
   }

   resetPassword() {
      axios.post('/auth/reset', {
         token: this.state.token,
         password: this.state.password
      })
         .then(data => {
            console.log(data)
            this.props.showLogin()
         })
         .catch(err => {
            console.log(err)
            Rollbar.error(err)
            toast.error('Failed to reset password')
         })
   }

   render() {
      return (
         <div className="main-container">
            <section className="imageblock switchable feature-large height-100">
               <div className="imageblock__content col-md-6 col-sm-4 pos-right">
                  <div className="background-image-holder">
                     <img alt="image" src="/login-bg.jpg" />
                  </div>
               </div>
               <div className="container pos-vertical-center">
                  <div className="row">
                     <div className="col-md-5 col-sm-7">
                        <img className="logo login-logo" alt="logo" src="/logo.png" />
                        <h2>Reset Password</h2>
                        <form onSubmit={e => e.preventDefault()}>
                           <div className="row">
                              <div className="col-xs-12">
                                 <input type="password" placeholder="Password" id="inputPassword" name="password" value={this.state.password} onChange={this.handleChange.bind(this)} onKeyPress={(e) => { if (e.key === 'Enter') { this.resetPassword() } }} />
                              </div>
                              <div className="col-xs-12">
                                 <button className="btn btn--primary type--uppercase login-btn" onClick={this.resetPassword.bind(this)}>Reset</button>
                              </div>
                              <div className="col-xs-12">
                                 
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      )
   }
}

export default ResetPassword;
