import React from "react";
import Rollbar from '../../utils/RollbarTracking'
import axios from 'axios'

import { toast } from 'react-toastify';

export class Password extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    oldPassword: '',
    password1: '',
    password2: '',
  }

  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  updatePassword = async () => {
    if (this.state.password1 === this.state.password2) {
      if (this.state.password1 === this.state.oldPassword) {
        toast.error("New password must be different than old password");
      }
      else {
        let requestParams = {
          'password': this.state.password1,
        }
        axios.post('/user/password/update', requestParams)
          .then(data => {
            console.log(data);
            toast.success('Password changed!')
          })
          .catch(err => {
            console.log(err)
            Rollbar.error(err)
          })
      }
    }
    else {
      toast.error("Passwords do not match");
    }
  }

  render() {
    return (
      <div id="account-password" className="account-tab">
        <h4>Password</h4>
        <p>Passwords must be at least 8 characters in length and contain both letters and numbers.</p>
        <form>
          <div className="row">
            <div className="col-xs-12">
              <label>Old Password:</label>
              <input type="password" value={this.state.oldPassword} onChange={this.handleChange} name="oldPassword" />
            </div>
            <div className="col-sm-6">
              <label>New Password:</label>
              <input type="password" value={this.state.password1} onChange={this.handleChange} name="password1" />
            </div>
            <div className="col-sm-6">
              <label>Retype New Password:</label>
              <input type="password" value={this.state.password2} onChange={this.handleChange} name="password2" />
            </div>
            <div className="col-md-3 col-sm-4">
              <button type="button" onClick={this.updatePassword} className="btn btn--primary type--uppercase btn-full">Save Password</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
