import React from "react";
import Rollbar from '../utils/RollbarTracking'
import axios from 'axios'
import { Link } from 'react-router-dom';

export class Home extends React.Component {

    state = {
        orders: [],
        supplierData: [],
        myCustomerData: {}
    }

    componentWillMount() {
      this.fetchCustomerData();
      this.fetchOrderList();
    }

    fetchCustomerData = async () => {
      axios.get('/user')
      .then(data => {
        console.log(data[0]);
        this.setState({
            myCustomerData: data[0]
        });
      })
      .catch (err => {
        console.log(err);
        Rollbar.error(err)
      })
    }

    fetchOrderList = async () => {
      axios.get('/orders')
      .then(resp => {
        console.log(resp);
        this.setState({
            orders: resp
        });
      })
      .catch (err => {
        console.log(err)
        Rollbar.error(err)
      })
    }

    statusColour(status) {
      if (status == 'Pending') {
        return <span className="text-warning">Pending</span>
      } else if (status == 'Accepted') {
        return <span className="text-info">Accepted</span>
      } else if (status == 'Invoiced') {
        return <span className="text-success">Invoiced</span>
      } else {
        return <span className="text-secondary">Paid</span>
      }
    }

    render() {
        return (
            <section className="space--xxs">
                <div className="container">
                  <h3>Home</h3>
                    <div className="row">
                        <div className="col-md-8">
                          <table className="border--round table--alternate-row box-shadow">
                            <thead>
                              <tr>
                                <th className="hidden-xs">Order No</th>
                                <th>Supplier</th>
                                <th>Date</th>
                                <th className="hidden-xs">Status</th>
                                <th>Total</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            {this.state.orders.length === 0 ? (
                              <tbody>
                                <tr>
                                  <td colSpan="6" className="text-center">There is no data to display</td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {this.state.orders.slice(0, 6).map((data, i) =>
                                <tr key={i}>
                                  <td className="hidden-xs">{data.orderID}</td>
                                  <td>{data.name}</td>
                                  <td>{data.date}</td>
                                  <td className="hidden-xs">{this.statusColour(data.status)}</td>
                                  <td>${data.hideTotal ? '0.00*' : data.total}</td>
                                  <td><Link className="btn btn--sm btn--primary" to={'/previousorders/orderdetails/' + data.orderID}><span className="btn__text">View</span></Link></td>
                                </tr>
                                )}
                              </tbody>
                            )}
                          </table>
                          <div className="text-center mb-2">
                            <Link className="btn btn--sm" to={'/previousorders'}><span className="btn__text">View More</span></Link>
                          </div>
                        </div>
                        <div className="col-md-4">
                            <div className="boxed boxed--lg boxed--border bg--secondary box-shadow">
                                <div className="text-block text-center">
                                    <img alt="avatar" src="/avatar.png" className="image--sm" />
                                    <span className="h5">{this.state.myCustomerData.name}</span>
                                    <span>{this.state.myCustomerData.company}</span><br />
                                    <span>{this.state.myCustomerData.address}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}
