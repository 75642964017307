import React from "react";
import Rollbar from '../utils/RollbarTracking'
import axios from 'axios'

import { Profile } from './settings/profile'
import { Suppliers } from './settings/suppliers'
import { Billing } from './settings/billing'
import { Password } from './settings/password'


export class Settings extends React.Component {

  state = {
    name: '',
    company: '',
    panelView: this.props.match.params.id ? 'suppliers' : 'profile',
  }

  componentWillMount() {
    this.fetchCustomerData();
  }

  fetchCustomerData = async () => {
    axios.get('/user')
      .then(data => {
        console.log(data);
        this.setState({
          name: data[0].name,
          company: data[0].company
        });
      })
      .catch(err => {
        console.log(err)
        Rollbar.error(err)
      })
  }

  render() {
    return (
      <section className="space--xxs">
        <div className="container">
          <h3>Settings</h3>
          <div className="row">
            <div className="col-md-4">
              <div className="boxed boxed--lg boxed--border bg--secondary box-shadow">
                <div className="text-block text-center">
                  <img alt="avatar" src="/avatar.png" className="image--sm" />
                  <span className="h5">{this.state.name}</span>
                  <span>{this.state.company}</span>
                </div>
                <hr />
                <div className="text-block">
                  <ul className="menu-vertical settings-menu">
                    <li>
                      <a onClick={() => { this.setState({ panelView: 'profile' }) }}>Profile</a>
                    </li>
                    <li>
                      <a onClick={() => { this.setState({ panelView: 'suppliers' }) }}>Suppliers</a>
                    </li>
                    {/* <li>
                      <a onClick={() => { this.setState({ panelView: 'billing' }) }}>Billing Details</a>
                    </li> */}
                    <li>
                      <a onClick={() => { this.setState({ panelView: 'password' }) }}>Password</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="boxed boxed--lg boxed--border box-shadow">
                {this.state.panelView === 'profile' ? <Profile updateSidebar={this.fetchCustomerData.bind(this)} /> : null}
                {this.state.panelView === 'suppliers' ? <Suppliers suppId={this.props.match.params.id} extID={this.props.match.params.extID}/> : null}
                {/* {this.state.panelView === 'billing' ? <Billing /> : null} */}
                {this.state.panelView === 'password' ? <Password /> : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
