import React from "react";
import Modal from 'react-responsive-modal';

export class YesNoModal extends React.Component {
    state = {
		modalOpen: true
    }

	onCloseModal = () => {
		this.setState({ modalOpen: false });
    };
    
    yes = () => {
        this.props.yes()
        this.onCloseModal()
    }
    
    render() {
        return (
            <Modal open={this.state.modalOpen} onClose={this.onCloseModal} center classNames={{ modal: 'boxed boxed--lg modal-custom' }}>
                <h2>Auto Saved Order</h2>
                <hr className="short" />
                <p className="lead">Would you like to continue with your previously started order?</p>
                <a className="btn bg--error spacer-top" onClick={()=> this.onCloseModal()}> <span className="btn__text">No</span> </a>
                <a className="btn btn--primary spacer-top" onClick={()=> this.yes()}> <span className="btn__text">Yes</span> </a>
            </Modal>
        );
    }
}
