import React from "react";
import Rollbar from '../../utils/RollbarTracking'
import axios from 'axios'
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';

export class Suppliers extends React.Component {

	state = {
		mySuppliers: [],
		supplierInput: '',
		modalOpen: false
	}

	componentWillMount() {
		this.fetchSuppliers();
	}

	handleSuppInput = (event) => {
		this.setState({ supplierInput: event.target.value });
	}

	addSupplier(extID) {
		var id = this.state.supplierInput;
		console.log(id);
		var valid = this.state.mySuppliers.find(x => x.supplierID === Number(id));
		console.log(valid)
		if (!valid) {
			axios.get('/suppliers/' + id)
				.then(data => {
					console.log(data);
					if (data.length > 0) {
						axios.post('/suppliers', {supplierID: id, extID: extID})
							.then(data => {
								console.log(data);
								this.fetchSuppliers();
								this.onCloseModal()
							})
							.catch(err => {
								console.log(err)
								Rollbar.error(err)
							})
					} else {
						toast.error("Please enter a valid supplier id");
					}
				})
				.catch(err => {
					console.log(err)
					Rollbar.error(err)
				})
		}
		else {
			// toast.error("Supplier already added!");
		}
	}

	removeSupplier = async (suppId) => {
		axios.delete('/suppliers/' + suppId)
			.then(data => {
				console.log(data);
				this.fetchSuppliers();
			})
			.catch(err => {
				console.log(err)
				Rollbar.error(err)
			})
	}

	fetchSuppliers = async () => {
		axios.get('/suppliers')
			.then(data => {
				console.log(data);
				this.setState({
					mySuppliers: data
				});
				if (this.props.suppId) {
					this.setState({
						supplierInput: this.props.suppId
					});
					this.addSupplier(this.props.extID)
				}
			})
			.catch(err => {
				console.log(err)
				Rollbar.error(err)
			})
	}

	onOpenModal = () => {
		this.setState({ modalOpen: true });
	};

	onCloseModal = () => {
		this.setState({ modalOpen: false });
	};

	render() {
		return (
			<div id="account-suppliers" className="account-tab">
				<div className="row m-0">
					<div className="col-xs-6 col-md-6">
						<h4 className="h-nopad">Suppliers</h4>
					</div>
					<div className="col-xs-6 col-md-6 text-right">
						<a className="btn btn--primary modal-trigger" onClick={this.onOpenModal}> <span className="btn__text">Add Supplier</span> </a>
						<Modal open={this.state.modalOpen} onClose={this.onCloseModal} center classNames={{ modal: 'boxed boxed--lg modal-custom' }}>
							<h2>Add Supplier</h2>
							<hr className="short" />
							<p className="lead">Please enter the supplier code below:</p>
							<input type="text" value={this.state.supplierInput} onChange={this.handleSuppInput} />
							<a className="btn btn--primary spacer-top" disabled={!this.state.supplierInput} onClick={()=> this.addSupplier(null)}> <span className="btn__text">Add</span> </a>
						</Modal>
					</div>
				</div>
				<p>Manage your different suppliers whom you can order from:</p>
				<form>
					<div className="row">
						<div className="col-md-12">
							<table className="border--round table--alternate-row">
								<thead>
									<tr>
										<th>Name</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{this.state.mySuppliers.map((data, i) =>
										<tr key={i}>
											<td>{data.name}</td>
											<td><a className="btn btn--primary btn--primary-2" onClick={this.removeSupplier.bind(this, data.supplierID)}> <span className="btn__text">Remove</span> </a></td>
										</tr>)}
								</tbody>
							</table>
						</div>
					</div>
				</form>
			</div>
		);
	}
}