import React from "react";
import {NavLink} from "react-router-dom";
import Cache from '@aws-amplify/cache';
import NavbarDropdown from 'react-navbar-dropdown'

export class Header extends React.Component {

    state = {
      showMenu: false
    }

    logout() {
        Cache.clear()
        window.location.replace('/')
    }

    toggleMenu() {
      this.setState({showMenu: !this.state.showMenu})
    }

    render() {
        return (
          <nav className="bar bar--sm bg--secondary" id="menu5">
              <div className="container">
                  <div className="row">
                      <div className="col-md-2 col-sm-3 col-xs-4">
                          <div className="bar__module">
                              <NavLink to={"/home"} activeClassName={""}>
                                  <img className="logo logo-dark" alt="logo" src="/logo.png" />
                                  <img className="logo logo-light" alt="logo" src="/logo.png" />
                              </NavLink>
                          </div>
                      </div>
                      <div className="col-xs-8 text-right hidden-sm hidden-md hidden-lg">
                        <a href="#" className="hamburger-toggle toggled-class" onClick={this.toggleMenu.bind(this)}>
                          <i className="icon icon--sm stack-interface stack-menu"></i>
                        </a>
                      </div>
                      <div className={this.state.showMenu ? "col-xs-12 col-md-4 col-sm-5" : "col-md-4 col-sm-5 hidden-xs"}>
                          <div className="bar__module" onClick={this.toggleMenu.bind(this)}>
                              <ul className="menu-horizontal">
                                  <li>
                                      <NavLink to={"/home"} activeClassName={"active"}>
                                          <i className="stack-interface stack-cog"></i> Home
                                      </NavLink>
                                  </li>
                                  <li>
                                      <NavLink to={"/neworder"} activeClassName={"active"}>
                                          <i className="stack-interface stack-plus-circled"></i> New Order
                                      </NavLink>
                                  </li>
                                  <li>
                                      <NavLink to={"/previousorders"} activeClassName={"active"}>
                                          <i className="stack-interface stack-basket"></i> Previous Orders
                                      </NavLink>
                                  </li>
                                  <li className="hidden-sm hidden-md hidden-lg">
                                    <NavLink to={"/settings"} activeClassName={"active"}>
                                          <i className="stack-interface stack-cog"></i> Settings
                                      </NavLink>
                                  </li>
                                  <li className="hidden-sm hidden-md hidden-lg">
                                      <a onClick={this.logout}>
                                        <i className="stack-interface stack-users"></i> Logout
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div className={this.state.showMenu ? "col-xs-12 col-md-6 col-sm-4 text-right text-left-xs" : "col-md-6 col-sm-4 text-right text-left-xs hidden-xs"}>
                          <div className="bar__module hidden-xs hidden-sm">
                              <ul className="menu-horizontal">
                                <li className="dropdown">
                                    <NavbarDropdown>
                                        <NavbarDropdown.Toggle className="dropdown__trigger">
                                            <NavbarDropdown.Open>
                                                <img alt="avatar" className="avatar image--xxs" src="/avatar.png" /> {this.props.userName}
                                            </NavbarDropdown.Open>
                                            <NavbarDropdown.Close>
                                                <img alt="avatar" className="avatar image--xxs" src="/avatar.png" /> {this.props.userName}
                                            </NavbarDropdown.Close>
                                        </NavbarDropdown.Toggle>
                                        <NavbarDropdown.Menu className="dropdown__container">
                                            <NavbarDropdown.Item>
                                            <div className="container text-left">
                                                <div className="row">
                                                    <div className="dropdown__content col-md-2 col-sm-4">
                                                        <ul className="menu-vertical">
                                                            <li className="">
                                                                <NavLink to={"/settings"} >
                                                                    Settings
                                                                </NavLink>
                                                            </li>
                                                            <li className="separate">
                                                                <a onClick={this.logout}>
                                                                    Logout
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            </NavbarDropdown.Item>
                                        </NavbarDropdown.Menu>
                                    </NavbarDropdown>
                                </li>
                            </ul>
                          </div>
                          <div className="bar__module" onClick={this.toggleMenu.bind(this)}>
                            <NavLink className="btn btn--primary btn--sm type--uppercase" to={"/neworder"} activeClassName={""}>
                              <span className="btn__text">
                                  Order
                              </span>
                            </NavLink>
                          </div>
                      </div>
                  </div>
              </div>
          </nav>
        );
    }
}
