import React from "react";
import Rollbar from '../utils/RollbarTracking'
import axios from 'axios'
import Scrollspy from 'react-scrollspy'
import { toast } from 'react-toastify';
import Cache from '@aws-amplify/cache';
import {YesNoModal} from '../components/YesNoModal'

export class NewOrder extends React.Component {

  state = {
    myProductsData: [],
    mySupplierData: [],
    allProducts: [],
    myCartData: [],
    myCategoryData: [],
    myDeliveryDays: [],
    orderTotal: 0,
    arrQty: [],
    deliveryDayValue: '',
    userId: this.props.userId,
    myCategoriesSimple: [],
    prevOrderData: {},
    comment: '',
    paymentTerms: null,
    announcement: '',
    showTemplate: false,
    template: [],
    hiddenPrices: false,
    fullTotal: 0,
    search: '',
    autoSaveModalOpen: false
  }

  componentWillMount() {
    this.props.match.params.id ? this.fetchProductList() && this.fetchSupplierData() && this.fetchCategoryData() && this.fetchDeliveryDays() : this.props.history.push("/neworder");
    if (Cache.getItem('autosave-order' + this.props.match.params.id) && Cache.getItem('autosave-order' + this.props.match.params.id).myCartData.length > 0) {
      this.setState({autoSaveModalOpen: true})
    }
  }

  // Auto save functions
  loadAutoSaveOrder() {
    console.log('loading autosaved order: ')
    console.log(Cache.getItem('autosave-order' + this.props.match.params.id))
    this.setState(Cache.getItem('autosave-order' + this.props.match.params.id), () => {
      this.fetchDeliveryDays();
    })
  }

  autoSaveOrder() {
    console.log('autosaving order state...')
    let expiry = new Date(new Date().getTime() + 86400000);
    Cache.setItem('autosave-order' + this.props.match.params.id, this.state, {expires: expiry})
  }

  // Input Control Functions
  handleQtyChange = (event) => {
    var valid = isNaN(event.target.value);
    if (valid == false && event.target.value != ' ') {
      var array = [...this.state.arrQty];
      array[event.target.name] = event.target.value
      this.setState({ arrQty: array }, ()=> {
        this.refreshCart()
      });
    } else {
      var array = [...this.state.arrQty];
      array[event.target.name] = ''
      this.setState({ arrQty: array });
    }
  };

  incrementQty = (id, action) => {
    var array = [...this.state.arrQty];
    if (!array[id]) {
      array[id] = 0;
    }
    if (action) {
      array[id] = Number(array[id]) + 1;
      this.setState({ arrQty: array }, ()=> {
        this.refreshCart()
      });
    }
    else {
      if (array[id] > 0) {
        array[id] = Number(array[id]) - 1;
        this.setState({ arrQty: array }, ()=> {
          this.refreshCart()
        });
      }
    }
  };

  handleDropdownChange = (event) => {
    this.setState({ deliveryDayValue: event.target.value }, () => {
      this.autoSaveOrder()
    });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.autoSaveOrder()
    });
    if (event.target.name === 'search') {
      this.searchProducts(event.target.value)
    }
  }

  // Cart management
  refreshCart() {
    var cart = []
    var fullTotal = 0
    var showTotal = 0 
    var hiddenPrices = false
    this.state.arrQty.forEach((qty, id) => {
      if (qty) {
        var index = this.state.allProducts.findIndex(x => x.productID === id);
        var product = this.state.allProducts[index]
        cart.push({
          product_id: product.productID,
          qty: qty,
          name: product.name,
          price: product.price,
          photo: product.photo,
          description: product.description,
          showPrice: product.showPrice
        })
        fullTotal += product.price*qty
        if (product.showPrice) {
          showTotal += product.price*qty
        } else {
          hiddenPrices = true
        }
      }
    })
    this.setState({
      myCartData: cart,
      orderTotal: showTotal,
      fullTotal: fullTotal,
      hiddenPrices: hiddenPrices
    }, () => {
      this.autoSaveOrder()
    });
  }

  deleteOrderItem(productID) {
    var array = [...this.state.arrQty];
    array[productID] = ''
    this.setState({ arrQty: array }, ()=> {
      this.refreshCart()
    });
  }

  // GET Data Requests
  fetchDeliveryDays = async () => {
    axios.get('/suppliers/' + this.props.match.params.id + '/deliverydays')
      .then(data => {
        console.log(data);
        this.setState({
          myDeliveryDays: data.deliveryDays,
          deliveryDayValue: data.deliveryDays[0].str,
          paymentTerms: data.paymentTerms,
          template: JSON.parse(data.template)
        });
      })
      .catch(err => {
        console.log(err)
        toast.error('Failed to load delivery days. Please refresh.')
        Rollbar.error(err)
      })
  }

  fetchProductList = async () => {
    axios.get('/products/' + this.props.match.params.id)
      .then(data => {
        console.log(data);
        this.setState({
          myProductsData: data,
          allProducts: data
        });
        if (this.props.match.params.prevOrderId) {
          this.fetchPrevOrderData()
        }
      })
      .catch(err => {
        console.log(err)
        toast.error('Failed to load products. Please refresh.')
        Rollbar.error(err)
        this.props.history.push("/error");
      })
  }

  fetchCategoryData = async () => {
    axios.get('/products/' + this.props.match.params.id + '/categories')
      .then(data => {
        console.log(data);
        var strippedCat = this.createCategoryArr(data);
        this.setState({
          myCategoryData: data,
          myCategoriesSimple: strippedCat
        });
      })
      .catch(err => {
        console.log(err)
        toast.error('Failed to load categories. Please refresh.')
        Rollbar.error(err)
      })
  }

  fetchSupplierData = async () => {
    axios.get('/suppliers/' + this.props.match.params.id)
      .then(data => {
        console.log(data);
        this.setState({
          mySupplierData: data[0],
          announcement: JSON.parse(data[0].settings).announcement
        });
      })
      .catch(err => {
        console.log(err)
        toast.error('Failed to load supplier details. Please refresh.')
        Rollbar.error(err)
        this.props.history.push("/error");
      })
  }

  // Save order
  placeOrder = async () => {
    // Remove emojis from comment
    var cleanedComment = this.state.comment.replace(/(?:[\u2700\-\u27bf]|(?:\ud83c[\udde6\-\uddff]){2}|[\ud800\-\udbff][\udc00\-\udfff]|[\u0023\-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70\-\udd71]|\ud83c[\udd7e\-\udd7f]|\ud83c\udd8e|\ud83c[\udd91\-\udd9a]|\ud83c[\udde6\-\uddff]|\ud83c[\ude01\-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32\-\ude3a]|\ud83c[\ude50\-\ude51]|\u203c|\u2049|[\u25aa\-\u25ab]|\u25b6|\u25c0|[\u25fb\-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600\-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9\-\u23f3]|[\u23f8\-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190\-\u21ff]|[\uE000\-\uF8FF]|\uD83C[\uDF00\-\uDFFF]|\uD83D[\uDC00\-\uDDFF])/g, '').replace(/[^A-Za-z0-9\w\.,\?""!@#\$%\^&\*\(\)\-_=\+;:<>\/\\\|\}\{\[\]`~\s\']*/g, '');
    let requestParams = {
      'supplier_id': this.props.match.params.id,
      'total': this.state.fullTotal.toFixed(2),
      'items': this.state.myCartData,
      'delivery_day': this.state.deliveryDayValue,
      'supplierSettings': JSON.parse(this.state.mySupplierData.settings),
      'comment': cleanedComment,
      'paymentTerms': this.state.paymentTerms === null ? JSON.parse(this.state.mySupplierData.settings).paymentTerms : JSON.parse(this.state.paymentTerms),
      'hideTotal': this.state.hiddenPrices
    }
    if (this.state.myCartData) {
      var minOrderVal = JSON.parse(this.state.mySupplierData.settings).minOrderValue
      if (this.state.fullTotal.toFixed(2) >= Number(minOrderVal)) {
        axios.post('/orders', requestParams)
        .then(data => {
          console.log(data);
          Cache.removeItem('autosave-order' + this.props.match.params.id);
          toast.success("Order placed successfully!");
          this.props.history.push("/home");
        })
        .catch(err => {
          console.log(err)
          toast.error('Failed to save order. Please refresh.')
          Rollbar.error(err)
          this.props.history.push("/error");
        })
      } else {
        toast.warning('Minimum order value is $' + minOrderVal)
      }
    }
  }

  // Reorder Functionality
  fetchPrevOrderData = async () => {
    axios.get('/orders/' + this.props.match.params.prevOrderId + '/lineitems')
      .then(data => {
        console.log(data);
        this.loadOrderItems(data.results);
      })
      .catch(err => {
        console.log(err)
        toast.error('Failed to load order. Please refresh.')
        Rollbar.error(err)
      })
  }

  loadOrderItems(lineItems) {
    var newArr = [];
    lineItems.forEach(line => {
      newArr[line.productID] = line.quantity
    })
    this.setState({arrQty: newArr}, () => {
      this.refreshCart()
    })
  }

  // Utils
  createCategoryArr(arr) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      newArr.push(arr[i].categoryID);
    }
    return newArr;
  }

  enabledBtn() {
    return (this.state.fullTotal === 0 && this.state.myCartData.length === 0) || this.state.myDeliveryDays.length === 0
  }

  // Order Template functions
  filterByTemplate() {
    var all = this.state.myProductsData
    var filtered = all.filter(e => this.state.template.includes(e.productID))
    this.setState({myProductsData: filtered})
  }

  toggleProductsView(bool) {
    this.setState({showTemplate: bool}, () => {
      if (this.state.showTemplate) {
        this.filterByTemplate()
      } else {
        this.fetchProductList()
      }
    })
  }

  starProduct(productID) {
    var template = this.state.template
    var index = template.findIndex(e=> e == productID)
    if (index == -1) {
      template.push(productID)
    } else {
      template.splice(index, 1)
    }
    this.setState({template: template}, () => {
      this.saveTemplate()
      if (this.state.showTemplate) {
        this.filterByTemplate()
      }
    })
  }

  saveTemplate = async () => {
    let requestParams = {
      'template': this.state.template,
      'supplierID': this.props.match.params.id
    }
    axios.post('/suppliers/template', requestParams)
        .then(data => {
          console.log(data);
        })
        .catch(err => {
          console.log(err)
          Rollbar.error(err)
          toast.error('Failed to save order template')
        })
  }

  // Search functions
  searchProducts(name) {
    var all = []
    if (this.state.showTemplate) {
      var filtered2 = this.state.allProducts.filter(e => this.state.template.includes(e.productID))
      all = filtered2
    } else {
      all = this.state.allProducts
    }
    var filtered = all
    if (name !== '') {
      filtered = all.filter(e => e.name.toLowerCase().includes(name.toLowerCase()))
    }
    this.setState({myProductsData: filtered})
  }

  render() {
    return (
      <section className="space--xxs">
        <div className="container">
          <h3>Place an Order</h3>
          <div className="row">
            <div className="col-md-2 sticky sticky-scroll hidden-xs">
              <div className="tabs-container tabs--vertical text-center">
                <Scrollspy items={this.state.myCategoriesSimple} offset={400} currentClassName="active" className="tabs">
                  {this.state.myCategoryData.map((data, i) =>
                    <li key={i}>
                      <a href={"#" + data.categoryID}>
                        <div className="tab__title">
                          <span href="#fnig" className="h5">{data.name}</span>
                        </div></a>
                    </li>
                  )}
                </Scrollspy>
              </div>
            </div>
            <div className="col-md-6">
              {this.state.announcement ? 
              <div className="alert bg--success">
                <div className="alert__body text-bold">
                  <span>{this.state.announcement}</span>
                </div>
              </div>
              : null}
              <div className="tabs-container mb-1 text-center">
                <ul className="tabs tabs-fluid">
                  <li className={this.state.showTemplate ? null : "active"} onClick={()=>this.toggleProductsView(false)}>
                    <div className="tab__title">
                      <span className="h5">All Items</span>
                    </div>
                  </li>
                  <li className={this.state.showTemplate ? "active" : null} onClick={()=>this.toggleProductsView(true)}>
                    <div className="tab__title">
                      <span className="h5">Order Template</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="input-icon mb-1">
                <i className="material-icons">search</i>
                <input type="text" name="search" value={this.state.search} onChange={this.handleChange} placeholder="Search by Product Name..." />
              </div>
              {this.state.myProductsData.map((data, i) =>
              <React.Fragment key={data.productID}>
                {i > 0 ? data.categoryName != this.state.myProductsData[i-1].categoryName ? 
                  <h4 id={data.categoryID} className="mb-0-5">{data.categoryName}</h4> 
                  : null : 
                  <h4 id={data.categoryID} className="mb-0-5">{data.categoryName}</h4>
                }
                <div className="boxed box-shadow custom-prod-card">
                  <div className="row no-margin">
                    <div className="col-xs-2 pr-0 p-sm-0">
                      {data.photo ? <img src={data.photo.replace('product_', 'resized-product_')}></img> : <img src="https://orderfresh-image-bucket-prod.s3-ap-southeast-2.amazonaws.com/productimgplaceholder_small.png" />}
                    </div>
                    <div className="col-xs-10 pl-0 pt-1 pl-0-5 lh-0">
                      <h5 className="label-surrounding">{data.name}</h5>
                      <i className={this.state.template.includes(data.productID) ? "fas fa-star star-icon" : "far fa-star star-icon"} onClick={() => this.starProduct(data.productID)}></i>
                      {data.stock === '0' ? <span className="label">Out of stock</span> : null}
                      <p className="lh-1">{data.description}</p>
                    </div>
                  </div>
                  <div className="row bg-gray">
                    <div className="col-xs-5 vcenter pl-2">
                      <h5 className="text-white">{data.showPrice ? '$' + data.price + '/' : null }{data.unit ? data.unit : 'unit'}</h5>
                    </div>
                    <div className="col-xs-7 text-right vcenter h-30">
                      <div className="quantity d-inline no-zoom">
                        <button className="plus-btn" type="button" onClick={() => this.incrementQty(data.productID, false)}>
                          <i className="fas fa-minus"></i>
                        </button>
                        <input disabled={!data.stock} value={this.state.arrQty[data.productID]} onChange={this.handleQtyChange} name={data.productID} placeholder="0" />
                        <button className="minus-btn mr-1-2" type="button" onClick={() => this.incrementQty(data.productID, true)}>
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>)}
                {this.state.myProductsData.length == 0 && this.state.showTemplate ? <h5 className="mb-2 text-center pr-1 pl-1">You have no items saved in your template. Click the <i className="fas fa-star text-gold"></i> icon to add a product.</h5> : null}
            </div>
            <div className="col-md-4">
              <div className="boxed boxed--border bg--secondary box-shadow supplier-info-card">
                {this.state.mySupplierData.photo ? 
                <div className="text-center img-sm-supplogo">
                  <img src={this.state.mySupplierData.photo.replace('profile_', 'resized-profile_')} />
                  <span className="h5">{this.state.mySupplierData.name}</span>
                </div>: null}
                <hr className="hr-nopad" />
                <div className="text-block">
                  <label>Delivery Day:</label>
                  {this.state.myDeliveryDays.length != 0 ? (
                    <div className="input-select">
                      <select value={this.state.deliveryDayValue} onChange={this.handleDropdownChange} className="form-control" id="sel1">
                        {this.state.myDeliveryDays.map((data, i) =>
                          <option key={i}>{data.str}</option>
                        )}
                      </select>
                    </div>) : <p>Delivery days not set, please contact supplier.</p>}
                </div>
              </div>
            </div>
            <div className="col-md-4 sticky float-right sticky-scroll">
              <div className="boxed boxed--border  bg--secondary box-shadow">
                <h3 className="h-nopad">Your Cart</h3>
                <hr className="hr-nopad" />
                {this.state.myCartData.map((data, i) =>
                  <div className="row m-0" key={i}>
                    <div className="col-xs-8 col-sm-8">
                      <span className="text-bold">{data.qty}x </span>
                      <span>{data.name}</span>
                    </div>
                    <div className="col-xs-4 col-sm-4 text-right text-bold">
                      <span className="prodCart">{data.showPrice ? '$' + (data.price * data.qty).toFixed(2) : null}</span>
                      <i className="fas fa-times prodCart-icon" onClick={() => this.deleteOrderItem(data.product_id)}></i>
                    </div>
                    <div className="col-sm-12">
                      <hr className="hr-nopad"></hr>
                    </div>
                  </div>
                )}
                <h4 className="h-nopad">Total ${this.state.orderTotal.toFixed(2)} {this.state.hiddenPrices ? '*' : null}</h4>
                {this.state.hiddenPrices ? <p className="small lh-1">* Total price does not include products that currently have no price</p> : null}
              </div>
              <div className="boxed boxed--border  bg--secondary box-shadow">
                <label>Comment:</label>
                <textarea className="bg--white" name="comment" rows="3" value={this.state.comment} onChange={this.handleChange} />
              </div>
              <a className="btn btn--primary btn--icon btn-full" href="#" onClick={this.placeOrder} disabled={this.enabledBtn()}> <span className="btn__text"><i className="icon-Checkout-Bag"></i>Place Order</span> </a>
            </div>
          </div>
          {this.state.autoSaveModalOpen ? <YesNoModal yes={this.loadAutoSaveOrder.bind(this)} /> : null}
        </div>
      </section>
    );
  }
}
